import {
    BAlert,
    BButton,
    BCol,
    BContainer,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormSelect,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BListGroupItem,
    BModal,
    BRow,
    BootstrapVueIcons,
    CardPlugin
} from "bootstrap-vue"

export default {
    install: function (Vue) {
        Vue.use(CardPlugin);
        Vue.use(BootstrapVueIcons);

        Vue.component('b-alert', BAlert);
        Vue.component('b-row', BRow);
        Vue.component('b-col', BCol);
        Vue.component('b-link', BLink);
        Vue.component('b-img', BImg);
        Vue.component('b-input-group', BInputGroup);
        Vue.component('b-form-input', BFormInput);
        Vue.component('b-form-select', BFormSelect);
        Vue.component('b-input-group-append', BInputGroupAppend);
        Vue.component('b-form-invalid-feedback', BFormInvalidFeedback);
        Vue.component('b-button', BButton);
        Vue.component('b-container', BContainer);
        Vue.component('b-form', BForm);
        Vue.component('b-form-group', BFormGroup);
        Vue.component('b-list-group-item', BListGroupItem);
        Vue.component('b-modal', BModal);
    }
}