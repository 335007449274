<template>
  <div class="route-list route-details collection-details">
    <b-container>
      <b-modal
        ref="modal"
        id="modal"
        :title="modalTitle"
        header-class="justify-content-center"
        centered
        hide-header-close
        hide-footer
        no-close-on-backdrop
      >
        <div class="d-block text-center">
          <div class="modalText">What do you want to do next?</div>
          <div class="modalItems">
            <div>
              <router-link
                :disabled="!$store.getters.isOnline"
                :event="$store.getters.isOnline ? 'click' : ''"
                :class="!$store.getters.isOnline ? 'disabled' : ''"
                :to="{
                  name: 'route-details',
                  params: { routeId: $route.params.routeId },
                }"
              >
                <b-icon
                  icon="arrow-left"
                  variant="primary"
                  style="width: 30px; height: 30px"
                ></b-icon
                ><br />
                Back to route details
              </router-link>
            </div>
            <div v-if="nextStop !== null">
              <router-link
                :disabled="!$store.getters.isOnline"
                :event="$store.getters.isOnline ? 'click' : ''"
                :class="!$store.getters.isOnline ? 'disabled' : ''"
                :to="{
                  name: 'route-item-details',
                  params: {
                    routeId: $route.params.routeId,
                    itemId: nextStop.id,
                  },
                }"
              >
                <b-icon
                  icon="house-door"
                  variant="primary"
                  style="width: 30px; height: 30px"
                ></b-icon
                ><br />
                Go to next stop<br />
                <b>{{ nextStop.address1 }} {{ nextStop.address2 }}</b>
              </router-link>
            </div>
          </div>
        </div>
      </b-modal>

      <b-row>
        <b-col cols="12" class="pb-1">
          <router-link
            :disabled="!$store.getters.isOnline"
            :event="$store.getters.isOnline ? 'click' : ''"
            :class="!$store.getters.isOnline ? 'disabled' : ''"
            :to="{
              name: 'route-details',
              params: { routeId: $route.params.routeId },
            }"
          >
            <b-icon
              icon="arrow-left"
              variant="primary"
              style="width: 30px; height: 30px"
            ></b-icon>
            Back to route details
          </router-link>
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col cols="12">
          <route-map :coordinates="model.coordinates" :zoom="15">
            <div class="info-window">
              <p class="text-center" :class="statusBarStyle">
                <b-icon
                  v-if="model.status === stopStatuses.collected"
                  icon="check2-square"
                  variant="secondary"
                  style="width: 20px; height: 20px"
                ></b-icon>
                <b-icon
                  v-if="model.status === stopStatuses.requiresAttention"
                  icon="exclamation-square"
                  variant="danger"
                  style="width: 20px; height: 20px"
                ></b-icon>
              </p>
              <p>
                <span
                  ><strong
                    >{{ model.firstName }} {{ model.lastName }}</strong
                  ></span
                >
              </p>
              <p>
                <span
                  >{{ model.address1 }} {{ model.address2 }} {{ model.city }}
                  {{ model.postcode }}</span
                >
              </p>
              <p><span>Number of trees: </span>{{ model.numberOfTrees }}</p>
              <p>
                <small>{{ model.instructions }}</small>
              </p>
              <p>
                <b-button
                  :variant="'outline-success'"
                  :href="`tel:${model.phoneNumber}`"
                  class="w-100"
                  >{{ model.phoneNumber }}
                </b-button>
              </p>
              <p>
                <b-button
                  :href="navigationLink"
                  target="_blank"
                  type="button"
                  variant="success"
                  class="w-100 color--white"
                >
                  Navigate
                </b-button>
              </p>
            </div>
          </route-map>
        </b-col>
      </b-row>
      <b-row class="p-2">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-textarea
                id="textarea-rows"
                placeholder="Notes ..."
                rows="4"
                v-model="$v.model.collectorNote.$model"
                :state="validationState($v.model.collectorNote)"
              ></b-form-textarea>
              <b-form-invalid-feedback
                :state="validationState($v.model.collectorNote)"
                >This field is required and must be between 10 and 150
                characters length
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row cols="12" class="mt-4">
            <b-col cols="6" v-if="!model.showChangeAsNoCollected">
              <b-button
                :disabled="!$store.getters.isOnline"
                type="button"
                variant="primary"
                class="button--large color--white"
                @click="changeStatus('completed', false)"
              >
                Collected
              </b-button>
            </b-col>
            <b-col cols="6" v-if="!model.showChangeAsNoCollected">
              <b-button
                :disabled="!$store.getters.isOnline"
                type="button"
                variant="danger"
                class="button--large color--white float-right"
                @click="changeStatus('missing', true)"
              >
                Missing
              </b-button>
            </b-col>
            <b-col
              cols="12"
              class="text-center"
              v-if="model.showChangeAsNoCollected"
            >
              <b-button
                :disabled="!$store.getters.isOnline"
                type="button"
                variant="primary"
                class="button--large color--white"
                @click="changeStatus('readytocollect', false)"
              >
                Mark as ready to collect
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-alert
        v-model="hasError"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000; text-align: center"
        variant="danger"
        dismissible
      >
        An error has occurred.
      </b-alert>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { BFormTextarea, BModal, VBModal } from "bootstrap-vue";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import ValidationMixins from "../mixins/validationMixins";
import RouteMap from "../components/RouteMap";
import { stopStatus } from "../domain/enums";

export default {
  name: "RouteItemDetails",
  components: {
    RouteMap,
    BFormTextarea,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  mixins: [ValidationMixins],
  beforeMount() {
    this.stopStatuses = stopStatus;
  },
  data() {
    return {
      model: Object,
      nextStop: Object,
      hasError: false,
      errorMessage: "",
      reportMissing: false,
      stopStatuses: null,
      modalTitle: "",
    };
  },
  mounted() {
    this.getDetails();
  },
  watch: {
    "$route.params.itemId": function (newId, oldId) {
      if (newId !== oldId) {
        this.getDetails();
        this.$refs["modal"].hide();
      }
    },
  },
  validations: {
    model: {
      collectorNote: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(150),
      },
    },
  },
  computed: {
    navigationLink() {
      if (this.model.coordinates) {
        return `https://www.google.com/maps/dir/?api=1&destination=${this.model.coordinates.lat},${this.model.coordinates.lng}`;
      }
      return "";
    },
    statusBarStyle() {
      if (this.model.status === stopStatus.readyToCollect)
        return "background--white";

      if (this.model.status === stopStatus.requiresAttention)
        return "background--amber";

      if (this.model.status === stopStatus.collected)
        return "background--pepper";

      return "";
    },
  },
  methods: {
    getDetails() {
      let self = this;
      this.$setBusy(true);
      let routeUrl =
        process.env.VUE_APP_ROUTE_URL +
        "/" +
        this.$route.params.routeId +
        "/item/" +
        this.$route.params.itemId;
      axios
        .get(routeUrl)
        .then(function (response) {
          self.model = response.data.stopDetails;
          self.nextStop = response.data.nextStop;

          self.$setBusy(false);
        })
        .catch(function () {});
    },
    changeStatus(status, shouldCheckValidations) {
      let self = this;
      this.$setBusy(true);

      if (shouldCheckValidations) {
        this.$v.$reset();
        this.$v.$touch();
        if (this.$v.$anyError) {
          this.$setBusy(false);
          return;
        }
      } else {
        self.hasError = false;
      }

      axios({
        method: "PUT",
        url:
          process.env.VUE_APP_ITEM_URL +
          "/" +
          this.$route.params.itemId +
          "/" +
          status,
        data: {
          message:
            this.model.collectorNote !== null ? this.model.collectorNote : "",
        },
      })
        .then(function () {
          if (self.nextStop) {
            self.modalTitle = "Tree Status changed!";
            self.$refs["modal"].show();
          } else {
            self.$router.push("/route/" + self.$route.params.routeId);
          }
        })
        .catch(function () {
          self.hasError = true;
          self.reportMissing = false;
          self.$setBusy(false);
        });
    },
  },
};
</script>
<style lang="scss">
.info-window {
  strong {
    font-weight: 600;
  }

  & > * {
    padding: 10px;
  }
}
</style>